import Icons from "../../icons.svg";
import './icons.css';

type TIcon = {
  name: string;
  size?: number;
  color?: string;
  className?: string;
};

export const Icon = ({
  size = 16,
  name,
  className,
  color = "currentColor",
}: TIcon) => {
  return (
    <svg
      className={`icon ${className ? " " + className : ""}`}
      height={size}
      width={size}
      fill={color}
    >
      <use href={`${Icons}#${name}`}></use>
    </svg>
  );
};
