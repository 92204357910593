import { LogoType } from "../Logotype";
import { Link } from "../link/Link";
import { Menu } from "../menu/menu";
import "./header.css";
import contactsData from "../../contacts.json";

export const Header = () => {
  const { phoneNumbers } = contactsData?.contacts;
  return (
    <div className="header">
      <Menu />
      <LogoType />
      <Link text={phoneNumbers?.cellNumber} icon="Phone" type="tel" />
    </div>
  );
};
