import { LogoType } from '../Logotype';
import { Link } from '../link/Link';
import './footer.css';
import footerData from '../../contacts.json';

export const Footer = () => {
  const { footerLink } = footerData;
  return (
    <div className="footer">
        <LogoType />
        <ul className='row'>
            {footerLink.map((info, i) => (
              <li key={i}><Link url={info.url} text={info.name} /></li>
            ))}
        </ul>
        <p>Дата создания частного образовательного учреждения «Кама-Автошкола» 24.03.2008 Учредитель: Арысланов Фаудат Галимарданович © ЧПОУ «Автошкола Кама» г. Альметьевск 2024 год. Все права защищены.</p>
    </div>
  );
};
