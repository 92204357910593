import { DocumentLink } from "../components/documentLink/documentLink";
export const DocumentPage = () => {
  const otherDocuments = [
    "Лицензия.pdf",
    "Положение об оказании платных услуг.pdf",
    "Свидетельство.pdf",
    "Устав.pdf",
    "Заявление в ГИБДД.pdf",
  ];
  return (
    <>
      <section className="documents">
        <h1> Документы автошколы</h1>
        <p>
          На этой странице вы можете скачать необходимые документы, связанные с
          автошколой <span className="accent">Кама-Автошкола.</span>
        </p>
        <h2 className="accent">Договор об обучении</h2>
        <p>
          Для начала обучения необходимо подписать договор об обучении. Вы
          можете скачать его по ссылке ниже:
        </p>
        <DocumentLink
          url={
            process.env.PUBLIC_URL + "assets/documents/Договор об обучении.pdf"
          }
          fileName="Договор об обучении.pdf"
          icon="PDF"
          text="Договор об обучении"
        />
        <h2 className="accent">Правила внутреннего распорядка</h2>
        <p>
          Правила внутреннего распорядка автошколы "Кама-автошкола" помогут вам
          лучше понять, как проходит обучение и какие правила необходимо
          соблюдать.
        </p>
        <DocumentLink
          url={
            process.env.PUBLIC_URL +
            "assets/documents/Правила внутреннего распорядка.pdf"
          }
          fileName="Правила внутреннего распорядка.pdf"
          icon="PDF"
          text="Правила внутреннего распорядка"
        />
        <h2 className="accent">Другие документы</h2>
        <p>
          Здесь вы можете найти другие документы, связанные с автошколой
          "Кама-автошкола", такие как:
        </p>
        <ul className="row">
          {otherDocuments.map((item, i) => (
            <li key={i}>
              <DocumentLink
                url={process.env.PUBLIC_URL + `assets/documents/${item}`}
                fileName={item}
                icon="PDF"
                text={item}
              />
            </li>
          ))}
        
        </ul>
      </section>
    </>
  );
};
