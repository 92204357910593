import { Icon } from '../icon/Icons';
import './button.css';

type TButton = {
  icon?: string;
  text?: string;
  type?: 'default' | 'primary' | 'soft';
  onClick?: () => void;
};

export const Button = ({ icon, text, type = "default", onClick }: TButton) => {
  return (
    <button className={`btn ${type.toLowerCase()}`} onClick={onClick}>
      {icon && <Icon name={icon}/>}
      {text && <span>{text}</span>}
    </button>
  );
};
