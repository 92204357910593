import "./App.css";
import { MainPage } from "./pages/MainPage";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import { DocumentsStudyPage } from "./pages/DocumentStudyPage";
import { Route, Routes } from "react-router-dom";
import { ContactPage } from "./pages/ContactPage";
import { DocumentPage } from "./pages/DocumentPage";

function App() {
  return (
    <div className="app">
      <Header />
      <main>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/documents-study' element={<DocumentsStudyPage />} />
          <Route path='/contacts' element={<ContactPage />} />
          <Route path='/documents-auto' element={<DocumentPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
