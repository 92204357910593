export const DocumentsStudyPage = () => {
  return (
    <>
      <section className="documents">
        <h1>Необходимые документы</h1>
        <p> Для начала обучения в автошколе <span className="accent">"Кама-Автошкола"</span> необходимо предоставить оригиналы следующих документов: </p>
        <ul>
            <li> Паспорт гражданина Российской Федерации </li>
            <li> СНИЛС </li>
            <li> Медицинское заключение о состоянии здоровья <span className="accent">(Форма 086/у)</span> </li>
            <li> Фотографии 3х4 см <span className="accent">(2 шт.)</span></li>
        </ul>
      </section>
    </>
  );
};
