import { useState } from "react";
import { Icon } from "../icon/Icons";
import "./documentLink.css";

type TDocumentLink = {
  url: string;
  icon?: string;
  text?: string;
  fileName: string;
  sizeIcon?: number;
};

export const DocumentLink = ({
  url,
  icon,
  text,
  fileName,
  sizeIcon = 32,
}: TDocumentLink) => {
  const [sizeDocument, setSizeDocument] = useState<string>("0");
  const [createdData, setCreatedData] = useState<string>(new Date().toString());

  // Запрос на получение размера файла и даты создания файла
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const file = new File([blob], "filename");
      const fileSize = (file.size / 1024).toFixed(2); // Получить размер файла
      const fileCreatedDate = file.lastModified; // Получить дату создания файла
      const formattedDate = new Date(fileCreatedDate).toLocaleDateString(
        "ru-RU"
      ); // Преобразование в строку в формате день.месяц.год
      setSizeDocument(fileSize);
      setCreatedData(formattedDate);
    });

  return (
    <a href={url} className="document-link" target="_blank">
      {icon && <Icon name={icon} size={sizeIcon} />}
      <div className="document-text">
        {text && <span className="document-link__text">{text}</span>}
        <div className="document-link__info">
          <span>{sizeDocument} кб</span>
          <span>{createdData}</span>
        </div>
      </div>
    </a>
  );
};
