import { Button } from "../components/button/Button";
import { Divider } from "../components/divider/Divider";
import { Photo } from "../components/photo/Photo";
import { Map } from "../components/map/Map";
import { useNavigate } from "react-router-dom";

type TMenuItem = {
  name: string;
  url: string;
  icon?: string;
};

export const MainPage = () => {
  const navigate = useNavigate();

  const menuItems: TMenuItem[] = [
    { name: "Документы автошколы", url: "/documents-auto", icon: 'Document' },
    { name: "Необходимые документы", url: "/documents-study", icon: 'Document' },
    { name: "Контакты", url: "/contacts",  icon: 'Phone'  },
    {
      name: "Тесты ПДД",
      url: "https://www.pdd24.com/pdd-onlain?ysclid=ltq2haarb0596075102",
      icon: "Book",
    },
  ];

  const navigateMenu = (item: TMenuItem) => {
    if (item.name !== "Тесты ПДД") navigate(item.url);
    else {
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", item.url);
      downloadLink.setAttribute("target", "_blank");
      downloadLink.click();
    }
  };

  const downloadFile = () => {
    const urlDocument =
      process.env.PUBLIC_URL + `assets/documents/Программа обучения.pdf`;
    const downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", urlDocument);
    downloadLink.setAttribute("target", "_blank");
    downloadLink.click();
  };
  return (
    <>
      <section className="hero">
        <h1>
          <span className="accent">«Кама-Автошкола»</span> <br /> города
          Aльметьевск
        </h1>
        <p>
          Обучись на ктегорию “B” всего за{" "}
          <span className="accent">34 000 рублей</span>. <br /> Действует
          рассрочка на <span className="accent">4 месяца!</span>
        </p>
        <ul className="row">
          {menuItems.map((item, i) => (
            <li key={i}>
              <Button
                icon={item.icon}
                text={item.name}
                type="soft"
                onClick={() => navigateMenu(item)}
              />
            </li>
          ))}
        </ul>
      </section>
      <Divider />
      <section className="about">
        <h2 className="accent">О нас</h2>
        <p>
          Наша автошкола имеет огромный стаж по обучению вождению автомобилей
          категории "В". Ежегодно автошкола выпускает более 200 граматно
          обученных водителей.
        </p>
        <p>
          Весь учебный процесс в автошколе проводится, согласно разработанным и
          утвержденным Министерством образования и науки Российской Федерации
          программам обучения.
        </p>
        <Photo
          name="car"
          url={process.env.PUBLIC_URL + "assets/images/car.png"}
          caption="Вождение на автодроме Кама-автошкола"
          frame
        />
        <p>
          В нашей автошколе предусмотренны несколько разделённых видов групп:
        </p>
        <ul>
          <li>
            Дневная <span className="accent">Пн-Пт 15:30-18:00</span>
          </li>
          <li>
            Вечерняя <span className="accent">Пн-Пт 15:30-18:00</span>
          </li>
          <li>
            Группа выходного дня{" "}
            <span className="accent">Пн-Пт 15:30-18:00</span>
          </li>
        </ul>
        <p>
          Именно это позволяет обучаться у нас людям разной занятости. Мы
          работаем как с начинающими, так и с опытными автолюбителями, которые
          хотят получить дополнительные уроки вождения.
        </p>
        <Map />
        <p>
          В "Кама-Автошкола" есть все условия для проведения теоретических
          занятий: учебно-тренировачный класс, компьютерный класс,
          мультимедийная система, учебные пособия и стенды. В целом, наша
          автошкола не стоит на месте и постоянно развивается.
        </p>
        <h3 className="accent">Этапы обучения в автошколе:</h3>
        <ul>
          <li>
            Инструктаж от дипломированного преподавателя по всему плану обучения
            и технике безопасности;
          </li>
          <li>Теоритические занятия;</li>
          <li>
            Практические заняти на современном автодроме и в городских условиях
            с опытным инструктором;
          </li>
          <Button
            text="Подробный план обучения"
            type="soft"
            onClick={() => downloadFile()}
          />
        </ul>
        <p>
          Если Вы решили научиться управлять легковым автомобилем, успешно сдать
          экзамены в ГИБДД, получить права и Вас интересует автошкола в городе
          Альметьевск, мы будем рады Вас видеть среди наших учахищся!
        </p>
      </section>
    </>
  );
};
