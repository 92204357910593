import { useState } from "react";
import { Button } from "../button/Button";
import "./menu.css";
import { DropDownLink } from "./link/link";

export const Menu = () => {
  const [isShow, setShow] = useState(false);

  return (
    <>
      <Button
        icon="Menu"
        type="default"
        onClick={() => setShow(!isShow)}
      />
      {isShow && (
        <ul className="dropdown">
          <li><DropDownLink text="Главная" icon="Home" url={'/'}/> </li>  
          <li><DropDownLink text="Необходимые документы" icon="Document" url={'/documents-study'}/> </li>
          <li><DropDownLink text="Документы автошколы" icon="Document" url={'/documents-auto'}/> </li>
          <li><DropDownLink text="Контакты" icon="Phone" url={'/contacts'}/> </li>
        </ul>
      )}
    </>
  );
};
