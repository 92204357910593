import './photo.css'

type TPhoto = {
    url: string;
    name?: string;
    frame?: boolean;
    caption?: string;
}

export const Photo = ({url, name, frame, caption}: TPhoto) => {
    return (
        <div className={`photo ${frame ? 'frame' : ''}`}>
            <img src={url} alt={caption} title={name}></img>
            {caption && <span>{caption}</span>}
        </div>
    )
}