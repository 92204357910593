import { Link } from "../components/link/Link";
import { Map } from "../components/map/Map";
import contactsData from "../contacts.json";

export const ContactPage = () => {
  const { phoneNumbers, socialNetwork, email } = contactsData.contacts;

  return (
    <>
      <section className="documents">
        <h1> Контакты </h1>
        <p>
          Мы рады ответить на все ваши вопросы и помочь вам с регистрацией на
          курсы обучения вождению. Вы можете связаться с нами по следующим
          контактам:
        </p>
        <h2 className="accent">Адрес</h2>
        <p>г.Альметьевск, ул. 8 Марта, 30В</p>
        <ul>
          <li>
            Пн-Пт <span className="accent">08:00-17:00</span>
          </li>
          <li>
            Перерыв <span className="accent">12:00-13:00</span>
          </li>
          <li>
            Сб-Вс <span className="accent">Выходной</span>
          </li>
        </ul>
        <Map />
        <h2 className="accent">Телефон</h2>
        <ul className="row">
          <li>
            <Link type="tel" text={phoneNumbers?.cellNumber} icon="Phone" />
          </li>
          <li>
            <Link type="tel" text={phoneNumbers?.officeNumber} icon="Phone" />
          </li>
        </ul>
        <p>По графику работы</p>
        <h2 className="accent">Email</h2>
        <Link icon="At" type="mail" text={email} />
        <h2 className="accent">Социальные сети</h2>
        <ul className="row">
          {socialNetwork.map((network, i) => (
            <li key={i}>
              <Link text={network?.name} url={network?.url} icon="Vkontakte" />
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};
