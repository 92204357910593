import { Icon } from "../../icon/Icons";
import { NavLink } from "react-router-dom";
import "./link.css";

type TDropDownLink = {
  url: string;
  icon?: string;
  text?: string;
};

export const DropDownLink = ({ url, icon, text }: TDropDownLink) => {
  return (
    <NavLink
      to={url}
      className={({ isActive, isPending }) =>
        isPending
          ? "dropdown-item"
          : isActive
          ? "dropdown-item active"
          : "dropdown-item"
      }
    >
      {icon && <Icon name={icon} />}
      {text && <span>{text}</span>}
    </NavLink>
  );
};
