import { Icon } from "../icon/Icons";
import './link.css'

type TLink = {
    url?: string;
    icon?: string;
    text?: string;
    type?: 'default' | 'tel' | 'mail';
};

export const Link = ({url, icon, text, type='default'}: TLink) => {
  return (
    <a href={`${type === 'tel' ? `tel:${text}` : (type === 'mail' ? `mailto:${text}` : `${url}`)}`} className='link' target={`${type === 'default' ? '_blank' : '_self'}`}>
      {icon && <Icon name={icon} />}
      {text && <span>{text}</span>}
    </a>
  );
};
